import React from "react";
import "../../../App.css";
import "./blog.css";

function Blog() {
    return (
        <h1> Test </h1>
    );
}


export default Blog;