import React from "react";
import "../../../App.css";
import Donate from "./donate.js";


function DonateMain(){
    return(
        <>
        <Donate/>
        
        </>
    );
}

export default DonateMain;