import React from "react";
import "../../../App.css";
import Contact from "./contact.js";
import "./contact.css";


function ContactMain(){
    return(
        <>
        <Contact/>        
        </>
    );
}

export default ContactMain;