import React from "react";
import "../../../App.css";
import Blog from "./blog.js";


function BlogMain(){
    return(
        
        <Blog/>
        
        
    );
}

export default BlogMain;